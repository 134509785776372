import React from 'react';
import useReactRouter from 'use-react-router';

import { BackBtn } from './styles.js';

function Back(props) {
  const { history, match } = useReactRouter();

  function handleOnPointerUp() {
    history.push('/' + match.params.page);
  }

  return (
    <BackBtn
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      onPointerUp={handleOnPointerUp}
      visible={props.visible}
    >
      <line x1="19" y1="12" x2="5" y2="12" />
      <polyline points="12 19 5 12 12 5" />
    </BackBtn>
  );
}

export default Back;
