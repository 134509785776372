import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import useReactRouter from 'use-react-router';

// hooks
import useHasScrolled from '../../hooks/useHasScrolled';

// components
//import Menu from './Menu'; // styled
import ArrowRight from './ArrowRight';
import BurgerMenu from './BurgerMenu'; // styled

// import { Router } from '../../routes';

const HeaderContainer = styled.div`
  height: ${p => p.theme.headerHeight};
  transition: height 0.2s;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 999;
  transition: none;

  ${p => p.theme.breakMobile} {
    height: ${p =>
      p.top >= 130
        ? p.theme.headerHeightMobileScrolled
        : p.theme.headerHeightMobile};

    margin-top: ${p => (p.top >= 130 ? '130px' : '0')};
    top: ${p => (p.top >= 130 ? '-130px' : `${-p.top}px`)};
    /*transition: top 0.1s;*/
    flex-direction: ${p => (p.top >= 130 ? 'row' : 'column')};
    align-items: center;
    justify-content: ${p => (p.top >= 130 ? 'space-between' : 'center')};
  }
`;

/*

height: ${p =>
  p.hasScrolled
    ? p.theme.headerHeightMobileScrolled
    : p.theme.headerHeightMobile};
flex-direction: ${p => (p.hasScrolled ? 'row' : 'column')};
align-items: center;
justify-content: ${p => (p.hasScrolled ? 'space-between' : 'center')};

 */

const Title = styled.h1`
  font-family: 'Oswald';
  font-weight: 300;
  font-size: 2.25rem;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  color: black;
  margin-bottom: 0;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 720px) {
    font-size: 1.75rem;
  }
  ${p => p.theme.breakMobile} {
    font-size: ${p => (p.top >= 130 ? '1.25rem' : '1.75rem')};
    flex: ${p => (p.top >= 130 ? 1 : 'none')};
    text-align: ${p => (p.top >= 130 ? 'left' : 'center')};
  }
`;

/*

${p => p.theme.breakMobile} {
  font-size: ${p => (p.hasScrolled ? '1.25rem' : '1.75rem')};
  flex: ${p => (p.hasScrolled ? 1 : 'none')};
  text-align: ${p => (p.hasScrolled ? 'left' : 'center')};
}
 */

const Subtitle = styled.div`
  font-family: 'Oswald';
  font-weight: 200;
  font-size: 1rem;
  text-transform: uppercase;
  color: black;
  opacity: 0.5;
  margin-bottom: 1rem;
  @media (max-width: 720px) {
    font-size: 0.875rem;
  }
  ${p => p.theme.breakMobile} {
    display: ${p => (p.top >= 130 ? 'none' : 'block')};
  }
`;

// menu components
const MenuWrapper = styled.div`
  position: relative;
  /*position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;*/
  /* just the BACKDROP! */
  &:after {
    content: '';
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 1);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s;
    ${p => p.theme.breakMobile} {
      display: block;
      opacity: ${p => (p.shown ? '0.5' : '0.0')};
      pointer-events: ${p => (p.shown ? 'all' : 'none')};
    }
  }
`;

const MenuInner = styled.div`
  list-style: none;
  padding: 0;
  margin: 0;
  min-height: 22px;
  font-family: 'PT Sans';
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  ${p => p.theme.breakMobile} {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 230px;
    background-color: white;
    z-index: 1001;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    text-align: right;
    align-items: flex-end;
    justify-content: center;
    transition: transform 0.4s;
    transform: ${p => (p.shown ? 'translateX(0)' : 'translateX(105%)')};
  }
`;

const MenuItem = styled.div`
  display: inline-block;
  font-size: 1rem;
  color: black;
  text-decoration: none;
  margin: 0;
  padding: 0;
  margin-left: 1rem;
  margin-right: 1rem;
  text-transform: uppercase;
  border: 0;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  border-color: ${p => (p.active ? 'black' : 'transparent')};
  &:hover {
    border-color: black;
  }
  @media (max-width: 720px) {
    font-size: 0.875rem;
    margin-left: 0.6rem;
    margin-right: 0.6rem;
  }
  ${p => p.theme.breakMobile} {
    font-size: 1rem;
    box-sizing: content-box;
    /* padding: 0.5rem 2rem; */
    padding: 0;
    margin-right: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

function Header(props) {
  const [shown, setShown] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [hasScrolled, top] = useHasScrolled();

  const { history, match, location } = useReactRouter();

  useEffect(() => {
    const url =
      window.location.protocol +
      '//' +
      window.location.hostname +
      location.pathname;
    //console.log('location', url);

    // call stacounter here?
    const script = document.getElementById('statcounter');
    if (script) document.body.removeChild(script);
    const newScript = document.createElement('script');
    newScript.id = 'statcounter';
    newScript.type = 'text/javascript';
    newScript.async = true;
    newScript.onload = function() {
      // remote script has loaded
    };
    newScript.src = 'https://www.statcounter.com/counter/counter.js';
    document.body.appendChild(newScript);
  }, [location]);

  // async function to fetch the menu from wp
  // called from useEffect
  async function fetchMenu() {
    try {
      const res = await fetch(
        process.env.REACT_APP_ENDPOINT + '/wp-json/andrew/v1/menu'
      );
      const fetchedMenuItems = await res.json();
      setMenuItems(fetchedMenuItems);
      props.setMenuReady(true);
      if (location.pathname === '/') {
        history.push('/' + fetchedMenuItems[0].slug);
      }
    } catch (err) {
      console.error(err);
    }
  }

  // fetch menu
  useEffect(() => {
    fetchMenu(); // call async function here, since useEffect cant' be async
  }, []);

  function handleToggleMenu() {
    setShown(!shown);
  }

  function handleGoto(slug) {
    history.push(`/${slug}`);
    setShown(false);
  }

  // dont show on video page
  if (match.params.video !== undefined) return null;

  return (
    <HeaderContainer hasScrolled={hasScrolled} top={top}>
      <Title
        onPointerUp={() => handleGoto(menuItems[0].slug)}
        hasScrolled={hasScrolled}
        top={top}
      >
        Andrew Grant-Christensen
      </Title>
      <Subtitle hasScrolled={hasScrolled} top={top}>
        Director of Photography
      </Subtitle>
      <BurgerMenu
        onPointerUp={handleToggleMenu}
        hasScrolled={hasScrolled}
        top={top}
      />
      <MenuWrapper
        className="menu-wrapper"
        shown={shown}
        onPointerUp={handleToggleMenu}
      >
        <MenuInner
          className="menu-inner"
          shown={shown}
          onPointerUp={e => e.stopPropagation()}
        >
          <ArrowRight onPointerUp={handleToggleMenu} />

          {menuItems.map((menuItem, i) => (
            <MenuItem
              key={i}
              to={menuItem.slug}
              active={match.params.page === menuItem.slug ? 1 : 0}
              onPointerUp={() => handleGoto(menuItem.slug)}
            >
              {menuItem.title}
            </MenuItem>
          ))}
        </MenuInner>
      </MenuWrapper>
    </HeaderContainer>
  );
}

export default Header;
