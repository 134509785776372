import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  padding: ${p => p.theme.textGutter}px;
`;

const Row = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  ${p => p.theme.breakTablePortrait} {
    flex-direction: column;
  }
`;
const Col2 = styled.div`
  flex: 1;
  margin-left: ${p => p.theme.textGutter}px;
  margin-right: ${p => p.theme.textGutter}px;
  margin-bottom: ${p => p.theme.textGutter}px;
  ${p => p.theme.breakTablePortrait} {
    margin: 0;
  }
`;

const Col1 = styled.div`
  flex: 2;
  margin-left: ${p => p.theme.textGutter}px;
  margin-right: ${p => p.theme.textGutter}px;
  margin-bottom: ${p => p.theme.textGutter}px;
  ${p => p.theme.breakTablePortrait} {
    flex: 1;
    margin: 0;
  }
`;

const Title = styled.h2`
  font-weight: normal;
  font-size: 24px;
  margin: 0;
`;
const P = styled.p`
  a {
    color: black;
  }
`;

const createMarkup = html => ({
  __html: html
});

function TextPage({ col1 = '', col2 = '', title = '' }) {
  return (
    <Container>
      <Row>
        <Col2>
          <Title>{title}</Title>
        </Col2>
      </Row>
      <Row>
        <Col1>
          <P dangerouslySetInnerHTML={createMarkup(col1)} />
        </Col1>
        <Col1>
          <P dangerouslySetInnerHTML={createMarkup(col2)} />
        </Col1>
      </Row>
    </Container>
  );
}

export default TextPage;
