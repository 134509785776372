import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(({ className }) => (
  <svg className={className} viewBox="0 0 22 22">
    <circle
      cx="11"
      cy="11"
      r="9"
      fill="transparent"
      strokeWidth="2"
      stroke="black"
      strokeDasharray="40px"
      strokeDashoffset="2px"
    />
  </svg>
))`
  width: 26px;
  height: 26px;
  margin-left: 10px;
  margin-top: 9px;
  opacity: 0.6;
  animation: ${rotate} 0.6s linear infinite;
`;

export default Spinner;
