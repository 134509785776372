import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import useBowser from '../../hooks/useBowser';
import useDetectPointer from '../../hooks/useDetectPointer';

import Page from '../Page';
import Header from '../Header';

const AppContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  padding-top: ${p => p.theme.headerHeight};
  transition: padding-top 0.2s;
  ${p => p.theme.breakMobile} {
    padding-top: ${p => p.theme.headerHeightMobile};
  }
`;

function App() {
  useBowser();
  const handlePointer = useDetectPointer();

  // menu will updaet this state when it is ready
  // this state is passed to page, so it knows it can render pages
  // reason: menu will load first available menu when location "/" is visited
  // so we need to wait for the menu
  const [menuReady, setMenuReady] = useState(false);

  return (
    <Router>
      <AppContainer onPointerMove={handlePointer}>
        <Route
          path="/:page?/:video?"
          render={renderProps => (
            <Header {...renderProps} setMenuReady={setMenuReady} />
          )}
        />
        <Route
          path="/:page?/:video?"
          render={renderProps => (
            <Page {...renderProps} menuReady={menuReady} />
          )}
        />
      </AppContainer>
    </Router>
  );
}

export default App;
