import { useEffect } from 'react';

function useDocumentMeta(tag, content) {
  useEffect(() => {
    document.head
      .querySelector(`meta[name="${tag}"]`)
      .setAttribute('content', content);
  });
}

export default useDocumentMeta;
