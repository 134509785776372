import React, { useState } from 'react';

import { ScrubberWrapper } from './styles.js';

export default function Scrubber({
  visible = false,
  progress = 0,
  handleScrub = () => {}
}) {
  const [hover, setHover] = useState(false);

  function handlePointerMove(e) {
    setHover(Math.round((e.pageX / window.innerWidth) * 100));
  }

  function handlePointerOut() {
    setHover(false);
  }

  return (
    <ScrubberWrapper
      onPointerDown={handleScrub}
      onPointerMove={handlePointerMove}
      onPointerOut={handlePointerOut}
      visible={visible}
      progress={progress}
      hover={hover}
    >
      <div>
        <div />
        <div />
      </div>
    </ScrubberWrapper>
  );
}
