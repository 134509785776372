import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: translate(-50%, -50%) scale(1) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) scale(1) rotate(360deg);
  }
`;

/**
 * Toggle playback
 */
const TogglePlayback = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  width: 120px;
  height: 120px;
  z-index: 1012;
  cursor: pointer;
  opacity: ${p => (p.visible ? '1.0' : '0.0')};
  transition: transform 0.4s, opacity 1s;
  pointer-events: ${p => (p.state === 'loading' ? 'none' : 'all')};
  &:hover {
    transform: translate(-50%, -50%) scale(1.04);
  }
  animation: ${p => (p.state === 'loading' ? 'inherit' : 'none !important')}
  animation: ${rotate} 1s linear infinite;
  ${p => p.theme.breakTablePortrait}{
    width: 80px;
    height: 80px;
  }
`;

export default ({
  className,
  state = 'paused',
  onPointerUp,
  visible,
  title = undefined
}) => (
  <TogglePlayback
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="white"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
    onPointerUp={onPointerUp}
    visible={visible}
    state={state}
  >
    <circle
      className="circle"
      cx="12"
      cy="12"
      r="10"
      strokeDasharray={state === 'loading' ? '48' : '68'}
    />
    {state === 'paused' && (
      <polygon className="play" points="10 8 16 12 10 16 10 8" fill="white" />
    )}
    {state === 'playing' && (
      <line className="pause" strokeWidth="2" x1="10" y1="15" x2="10" y2="9" />
    )}
    {state === 'playing' && (
      <line className="pause" strokeWidth="2" x1="14" y1="15" x2="14" y2="9" />
    )}
  </TogglePlayback>
);
