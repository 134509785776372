import styled from 'styled-components';

export default styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1005;
  background-color: black;
  opacity: ${p => p.opacity};
  transition: opacity 1s;
`;
