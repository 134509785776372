import { useLayoutEffect } from 'react';
import Bowser from 'bowser';

function useBowser(def = 'touch') {
  useLayoutEffect(() => {
    const { parsedResult } = Bowser.getParser(
      window.navigator.userAgent
    ).parse();
    window.bowser = {
      name: parsedResult.browser.name,
      version: parsedResult.browser.version,
      type: parsedResult.platform.type // mobile, tablet, desktop
    };
    //console.log(window.bowser);
  }, []);
}

export default useBowser;
