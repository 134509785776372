import React, { createContext } from 'react';
import { render } from 'react-dom';
import { ThemeProvider } from 'styled-components';
import App from './components/App';
import './assets/fontface.css';
// if (!('PointerEvent' in window)) {
//   require('@wessberg/pointer-events');
// }
require('dotenv').config();

const theme = {
  headerHeight: '220px',
  headerHeightMobile: '190px',
  headerHeightMobileScrolled: '60px',
  // breakMobile: '@media (max-width: 520px)',
  breakMobile: '@media (max-width: 764px)',
  breakTablePortrait: '@media (max-width: 768px)',
  breakTablet: '@media (max-width: 1024px)',
  textGutter: 40
};

// to use with useContext hook
export const ThemeContext = createContext(theme);

(async function() {
  if (!('PointerEvent' in window)) await import('@wessberg/pointer-events');
  render(
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>,
    document.getElementById('root')
  );
})();

/*
<!-- Default Statcounter code for andrew
http://www.andrewgrantchristensen.com -->
<script type="text/javascript">
  var sc_project = 3704153;
  var sc_invisible = 1;
  var sc_security = '636106df';
</script>
<script
  type="text/javascript"
  src="https://www.statcounter.com/counter/counter.js"
  async
></script>
<!-- End of Statcounter Code -->


 */
