import React, { useState } from 'react';
import useReactRouter from 'use-react-router';
import styled from 'styled-components';

import VideoItem from './VideoItem';

const PageWrapper = styled.div`
  width: 100%;
`;

const VideoGridContainer = styled.div`
  /*flex: 1;*/
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;

  @media (max-width: 1180px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 520px) {
    grid-template-columns: 1fr;
  }
`;

function VideoGrid(props) {
  const [selected, setSelected] = useState(null);

  const { history } = useReactRouter();

  function handleVideoClick(e, pageSlug, videoSlug) {
    // check if test vid
    if (videoSlug === undefined) return;
    // if touch device, the video needs to be selected first!
    /*
    if (e.pointerType === 'touch' && selected !== videoSlug) {
      //if (selected !== videoSlug) {
      setSelected(videoSlug);
      return;
    }
    */

    if (e.pointerType === 'touch') {
      //if (selected !== videoSlug) {
      setSelected(videoSlug);
      return;
    }

    // reset selected video
    setSelected(null);
    // set the next video to autoplay! (this video)
    window.autoPlayNextVideo = true;
    history.push(`/${pageSlug}/${videoSlug}`);
  }

  return (
    <PageWrapper>
      <VideoGridContainer>
        {props.page.videos.map((video, i) => (
          <VideoItem
            key={i}
            i={i}
            video={video}
            selected={selected === video.slug}
            onPointerUp={e => handleVideoClick(e, props.page.slug, video.slug)}
          />
        ))}
      </VideoGridContainer>
    </PageWrapper>
  );
}

export default VideoGrid;
