import { useState } from 'react';

function useFormInput(initialValue) {
  const [value, setValue] = useState(initialValue);
  function onHandleChange(e) {
    setValue(e.target.value);
  }
  return { value, onChange: onHandleChange };
}

export default useFormInput;
