import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import useReactRouter from 'use-react-router';

import useWindowAspectRatio from '../../hooks/useWindowAspectRatio';
import useHighestQualityVideo from '../../hooks/useHighestQualityVideo';

import Video from './Video';
import TogglePlayback from './TogglePlayback';
import Back from './Back';
import ToggleAudio from './ToggleAudio';
import Scrubber from './Scrubber.js';

import { VideoPlayerWrapper, Title, Poster, VideoGrad } from './styles.js';

export default function VideoPlayer(props) {
  // --------------------
  // state
  // --------------------
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(window.globalMute);
  const [active, setActive] = useState(false); // used to show/hide gui
  const [hasPlayed, setHasPlayed] = useState(false); // set to true first time video plays
  const [videoReady, setVideoReady] = useState(true);
  const [buffering, setBuffering] = useState(false);
  const [progress, setProgress] = useState(0);

  const videoElem = useRef(null);
  const gradElem = useRef(null);

  // refs for the whole "mouse movement shows gui" stuff
  const disableMovement = useRef();
  const disableMovementTimeout = useRef();
  const activeTimeout = useRef();

  // router
  const { match } = useReactRouter();

  // --------------------
  // Effects
  // --------------------

  // setup video events
  useLayoutEffect(() => {
    // when video is ready to play for the first time
    function handleOnCanPlay() {
      // for some reason it can play, before it is mounted??
      if (videoElem.current === null) return;
      // setVideoReady(true);
      setBuffering(false);
      if (window.autoPlayNextVideo === true) {
        window.autoPlayNextVideo = false;
        videoElem.current.play();
      }
    }

    function handleOnWaiting() {
      setBuffering(true);
    }

    function handlePlaying() {
      setBuffering(false); // is this a real event? what about "play?"
    }

    // when video ends, reset the video and the state
    function handleOnEnded() {
      videoElem.current.currentTime = 0;
      setPlaying(false);
      setHasPlayed(false);
    }

    // trieggered when video plays (either auto or by user)
    function handlePlay() {
      setPlaying(true);
      setHasPlayed(true);
      setActive(false); // hide GUI for timeout
      disableMovement.current = true;
      disableMovementTimeout.current = setTimeout(() => {
        disableMovement.current = false;
      }, 1000);
    }

    // trieggered when video pauses (either auto or by user)
    function handlePause() {
      setPlaying(false);
    }

    function handleTimeUpdate() {
      setProgress(
        Math.round(
          (videoElem.current.currentTime / videoElem.current.duration) * 100
        )
      );
    }

    videoElem.current.oncanplay = handleOnCanPlay;
    //videoElem.current.addEventListener('canplay', handleOnCanPlay);
    videoElem.current.onwaiting = handleOnWaiting;
    videoElem.current.playing = handlePlaying;
    videoElem.current.onended = handleOnEnded;
    videoElem.current.addEventListener('play', handlePlay);
    videoElem.current.addEventListener('pause', handlePause);
    videoElem.current.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      //videoElem.current.removeEventListener('canplay', handleOnCanPlay);
      videoElem.current.removeEventListener('play', handlePlay);
      videoElem.current.removeEventListener('pause', handlePause);
      videoElem.current.removeEventListener('timeupdate', handleTimeUpdate);
      clearTimeout(disableMovementTimeout.current);
      clearTimeout(activeTimeout.current);
    };
  }, []);

  // set volume at mount
  useEffect(() => {
    videoElem.current.volume = muted === true ? 0 : 1.0;
  });

  const gradStyle = useWindowAspectRatio(16, 9);

  // set body overflow
  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  });

  // --------------------
  // Helpers
  // --------------------

  /**
   * When then playback toggle button is clicked
   */
  function handleTogglePlayback() {
    if (playing) {
      videoElem.current.pause();
    } else {
      videoElem.current.play();
    }
  }

  /**
   * When the mute/unmute button is clicked
   */
  function handleToggleAudio() {
    videoElem.current.volume = muted ? 1.0 : 0;
    setMuted(!muted);
    window.globalMute = !muted;
  }

  /**
   * When pointer moves over video, show gui
   * set a timer to 1 sec to hide gui again
   */
  function handleMovement() {
    if (disableMovement.current === true) return;
    if (active === false) {
      setActive(true);
      clearTimeout(activeTimeout.current);
      activeTimeout.current = setTimeout(() => {
        setActive(false);
      }, 1000);
    }
  }

  /**
   * When the back button is clicked
   */

  function handleScrub(e) {
    const time = e.pageX / window.innerWidth;
    videoElem.current.currentTime = videoElem.current.duration * time;
  }

  // --------------------
  // Render
  // --------------------

  const video = props.videos.find(video => video.slug === match.params.video);
  if (video === undefined) return null;

  const videoSrc = useHighestQualityVideo(video);

  const guiVisible = active || !playing;

  let videoState = playing ? 'playing' : 'paused';
  if (videoReady === false) videoState = 'loading';
  if (buffering === true) videoState = 'loading';

  // calc video opacity
  let videoOpacity = 1.0;
  if (guiVisible) videoOpacity = 0.5;
  if (!hasPlayed) videoOpacity = 0.0;

  return (
    <VideoPlayerWrapper onPointerMove={handleMovement}>
      <Poster src={video.poster.sizes.large} opacity={hasPlayed ? 0.0 : 0.5} />
      <Video
        ref={videoElem}
        visible={hasPlayed ? 1 : 0}
        onPointerUp={handleTogglePlayback}
        opacity={videoOpacity}
        preload="auto"
        playsInline={true}
      >
        <source src={videoSrc} type="video/mp4" />
      </Video>
      <VideoGrad ref={gradElem} visible={guiVisible && hasPlayed ? 1 : 0}>
        <div style={gradStyle} />
      </VideoGrad>
      <Back visible={guiVisible ? 1 : 0} />
      <Title visible={guiVisible ? 1 : 0}>{video.title}</Title>
      <TogglePlayback
        title="test"
        state={videoState}
        onPointerUp={handleTogglePlayback}
        visible={guiVisible || buffering ? 1 : 0}
      />
      <ToggleAudio
        muted={muted ? 1 : 0}
        onPointerUp={handleToggleAudio}
        visible={guiVisible ? 1 : 0}
      />
      <Scrubber
        visible={guiVisible ? 1 : 0}
        progress={progress}
        handleScrub={handleScrub}
      />
    </VideoPlayerWrapper>
  );
}
