import React from 'react';
import styled from 'styled-components';

const ArrowRight = styled(({ className, onPointerUp }) => (
  <svg className={className} viewBox="0 0 22 22" onPointerUp={onPointerUp}>
    <path d="M0 11 L21 11" stroke="black" strokeWidth="2" />
    <path
      d="M11 0 L21 11 L11 22"
      stroke="black"
      fill="transparent"
      strokeWidth="2"
    />
  </svg>
))`
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 1rem;
  right: 1rem;
  box-sizing: content-box;
  padding: 1rem;
  &:hover,
  &:active {
    opacity: 0.6;
  }
  display: none;
  ${p => p.theme.breakMobile} {
    display: block;
  }
`;

export default ArrowRight;
