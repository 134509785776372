import { useLayoutEffect } from 'react';

function useDetectPointer(def = 'touch') {
  // updates the window variables based on pointer input
  function handlePointer(e) {
    const { pointerType } = e;

    let newPointerType;
    if (pointerType === 'mouse') {
      window.hasMoused = true;
      newPointerType = 'mouse';
    }
    if (pointerType === 'touch') {
      window.hasTouched = true;
      newPointerType = 'touch';
    }
    if (window.hasTouched && window.hasMoused) {
      newPointerType = 'hybrid';
    }
    window.pointerType = newPointerType;
    // console.log(window.pointerType);
  }

  useLayoutEffect(() => {
    window.pointerType = def; // 'touch' | 'mouse' | 'hybrid'
    window.hasTouched = false;
    window.hasMoused = false;
  }, []);

  // we need to set pointer events on react element or safari wont work...
  return handlePointer;
}

export default useDetectPointer;
