import { useRef } from 'react';
/**
 * Takes a pointer up event that simulates a "onClick" event that will not
 * fire if the pointer have moved (scrolled)
 */

function usePointerClick(onPointerUp) {
  const lastMouseDown = useRef(Infinity);

  // record the position when the down event is fired
  function handlePointerDown(e) {
    lastMouseDown.current = e.pageX;
  }

  function handlePointerUp(e) {
    // calc diff compared to the pointerDown event
    const diff = Math.abs(lastMouseDown.current - e.pageX);
    // only fire if not scrolling has happened
    // console.log('DIFF', diff);
    if (diff < 3) onPointerUp(e);
    //if (diff === 2) onPointerUp(e);
    //reset last mouseDown
    lastMouseDown.current = Infinity;
  }

  // return the two events for the elem
  return {
    onPointerUp: handlePointerUp,
    onPointerDown: handlePointerDown
  };
}

export default usePointerClick;
