import React from 'react';
import styled from 'styled-components';

const BurgerMenu = styled(({ className, onPointerUp }) => (
  <svg className={className} viewBox="0 0 18 18" onPointerUp={onPointerUp}>
    <path d="M0 4 L18 4" stroke="black" strokeWidth="2" />
    <path d="M0 10 L18 10" stroke="black" strokeWidth="2" />
    <path d="M0 16 L18 16" stroke="black" strokeWidth="2" />
  </svg>
))`
  cursor: pointer;
  /*margin-left: ${p => (p.hasScrolled ? 0 : 'auto')};
  margin-right: ${p => (p.hasScrolled ? 0 : 'auto')};*/
  width: 18px;
  box-sizing: content-box;
  padding: 1rem;
  /* border-radius: 100%; */

  cursor: pointer;
  &:hover,
  &:active {
    opacity: 0.6;
  }
  display: none;
  ${p => p.theme.breakMobile} {
    display: block;
  }
`;

export default BurgerMenu;
