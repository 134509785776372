import { useState, useLayoutEffect } from "react";

function useHasScrolled() {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [top, setTop] = useState(0);

  useLayoutEffect(() => {
    function handleScroll() {
      //taken from jQuery
      const doc = document.documentElement;
      const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
      setHasScrolled(top > 0);
      setTop(top);
    }

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // <-- fore once at mount...

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return [hasScrolled, top];
}

export default useHasScrolled;
