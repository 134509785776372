function useHighestQualityVideo(video) {
  // TODO take netspeed into account?
  if (video.vid_1080 !== undefined && video.vid_1080 !== '')
    return video.vid_1080;
  if (video.vid_720 !== undefined && video.vid_1080 !== '')
    return video.vid_720;
  return video.vid_sd;
}

export default useHighestQualityVideo;
