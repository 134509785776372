import React from 'react';

import { ToggleAudioBtn } from './styles.js';

function ToggleAudio({ onPointerUp, muted, visible }) {
  return (
    <ToggleAudioBtn
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      onPointerUp={onPointerUp}
      visible={visible}
    >
      <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5" />
      {!muted && (
        <path
          className="levels"
          d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"
        />
      )}
    </ToggleAudioBtn>
  );
}

export default ToggleAudio;
