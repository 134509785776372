import { useEffect } from 'react';

function useDocumentTitle(title = '', prepend = true) {
  useEffect(() => {
    let finalTitle = title;
    if (title !== '' && prepend) finalTitle += ' | ';
    if (prepend) finalTitle += 'Andrew Grant-Christensen';
    document.title = finalTitle;
  });
}

export default useDocumentTitle;
